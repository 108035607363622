






import { Component, Prop, Vue } from 'vue-property-decorator'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import Model from '@/shared/classes/model'
import ChecklistService from '@/shared/modules/checklist/checklist.service'
import IResponse from '@/shared/interfaces/modules/response.interface'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ }
})
export default class SelectedChecklistsReport extends Vue {
  @Prop() selected!: Array<Model<IModelResponse>>
  @Prop() meta!: any

  downloadSelected() {
    ChecklistService.report(this.meta.company, this.selected.map((checklist: Model<IModelResponse>) => checklist.uuid))
      .then((response: IResponse<any>) => {
        downloadFile(response, 'test.pdf')
      })
  }
}
