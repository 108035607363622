






import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import Checklist from '@/shared/modules/checklist/checklist.model'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import permissions from '@/shared/helpers/permissions.helper'
import SelectAction from '@/shared/classes/components/data-table/data-table-select-actions'
import SelectedChecklistsReport from '@/company/components/checklists/SelectedChecklistsReport.vue'
import can from '@/shared/helpers/can.helper'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import router from '@/shared/router'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class ChecklistsIndex extends Vue {
  table: DataTableBase = new DataTableBase()
    .setModel(Checklist)
    .setMultipleCheckbox(true)
    .setDoubleClick(this.showChecklist)
    .setSelectActions([
      new SelectAction()
        .setEnabled(can([permissions.company.checklists.report]))
        .setMeta({
          company: this.company,
        })
        .setComponent(SelectedChecklistsReport),
    ])
    .setEndpoint(`company/${ this.company.slug }/checklists`)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setText(__('company.views.checklists.index.table.columns.name')),
      new DataTableHeader()
        .setKey('group.project.name')
        .setText(__('company.views.checklists.index.table.columns.project')),
      new DataTableHeader()
        .setKey('responsible.full_name')
        .setEntryKey('responsible.fullName')
        .setText(__('company.views.checklists.index.table.columns.responsible')),
      new DataTableHeader()
        .setKey('created_at')
        .setEntryKey('createdAt')
        .setText(__('company.views.checklists.index.table.columns.created-at')),
    ])
  .setFilters([
    new DataTableFilter()
      .setOperator(FilterOperators.in)
      .setKeyIsPrimary()
      .setField(
        new SearchableField()
          .isMultiple()
          .setIsAttached(false)
          .setKey('group.project_uuid')
          .setTitle(__('company.views.checklists.index.table.filters.project'))
          .loadItems({ endpoint: `company/${ this.company.slug }/projects`, value: 'uuid', title: 'name', perPage: 20 }),
      ),
    new DataTableFilter()
      .setOperator(FilterOperators.in)
      .setKeyIsPrimary()
      .setField(
        new SearchableField()
          .isMultiple()
          .setIsAttached(false)
          .setKey('responsible_uuid')
          .setTitle(__('company.views.checklists.index.table.filters.responsible'))
          .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
      ),
    new DataTableFilter()
      .setOperator(FilterOperators.gte)
      .setKeyIsPrimary()
      .setField(
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('created_at')
          .setTitle(__('company.views.checklists.index.table.filters.created-at-from')),
      ),
    new DataTableFilter()
      .setOperator(FilterOperators.lte)
      .setKeyIsPrimary()
      .setField(
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('created_at')
          .setTitle(__('company.views.checklists.index.table.filters.created-at-to')),
      )
  ])

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  showChecklist(prop: Checklist): void {
    if (this.user.hasDynamicPermissions([permissions.company.checklists.show])) {
      router.push({ path: `${ router.currentRoute.path }/${ prop.uuid }`})
    }
  }
}
